/* eslint-disable */
export const POSITIVE_NUMBERS = /^[1-9][0-9]*$/i;
export const EMAIL = /^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,63}$/i;
export const PASSWORD = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[0-9])(?=(.*[\W]){1,})(?!.*\s).{8,16}$/;
export const AGENT_PASSWORD = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[0-9]).{8,16}$/;
export const PERCENTAGE = /(^0$)|(^100$)|(^[1-9]([0-9]?)$)/i;
export const PERCENTAGE_COSTS = /(^100(\.0{1,3})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,3})?$)/i;
export const URL = /^(http|https)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,13}(\/\S*)?$/;
export const URL_AND_IP =
  /^(http(s?):\/\/)(((www\.)?[a-zA-Z0-9\.\-\_]+(\.[a-zA-Z]{2,10}))|(\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b))(\/[a-zA-Z0-9\_\-\s\.\/\?\%\#\&\=]*)?$/;
export const META_TAG = /^[a-zA-Z0-9 _-]+$/i;
export const FLOATING_NUMBER = /^[+]?\d+([.]\d+)?$/i;
export const FLOATING_NUMBER_DECIMAL_LENGTH_OF_TWO = /^\d{1,12}(\.\d{1,2})?$/i;
export const PAYMENT_NAME = /^[a-zA-Z0-9_/&@ ]{1,25}$/;
export const PROMO_KEY = /^[a-zA-Z0-9]*[a-zA-Z]{1,}[a-zA-Z0-9]*$/;
export const NAME = /^(?!-)(?!.*--)[^0-9\[\]\\`~!@#$%^&*()_+={};:<>|.\/?,"']+$/i;
export const SECOND_NAME = /^(?!-)(?!.*--)[^0-9\[\]\\`~!@#$%^&*()_+={};:<>|.\/?,"']+$/i;
export const LAST_NAMES = /^(?!-)(?!.*--)[^0-9\[\]\\`~!@#$%^&*()_+={};:<>|.\/?,"']+$/i;
export const OTHER_NAMES = /^(?!-)(?!.*--)[^0-9\[\]\\`~!@#$%^&*()_+={};:<>|.\/?,"']+$/i;
export const POST_BACK_KEY = /^[^\[\]\\`~!#$^&*()+={};:<>|\/?,"'\s]+$/;
export const TEXT = /([a-z0-9\\W])$/i;
export const ACCOUNT_USER_NAME = /^(?!.*--)[^\[\]\\`~!#$^&*()={};<>|\/?"']+$/i;
export const SECONDARY_NAME = /^(?!.*--)[^\[\]\\`~!#^&*()={};<>|\/?"\']+$/i;
export const PHONE_NUMBER = /^[0-9]+$/i;
export const POST_CODE = /^[a-z0-9]{2,}$|^[a-z0-9]+[-|\ ][a-z0-9]+$/i;
export const SKYPE = /^[A-Za-z\d\,\-\.\_\:]{6,32}$/i;
export const CITY_NAME = /^(?!.*--)[^\[\]\\`~!@#$%^&*()_+={};:<>|?"']+$/i;
export const COMPANY_NAME = /^.{1,50}$/i;
export const ADDRESS = /^(?!.*--)[^\[\]\\`~!@#$%^&*()_+={};:<>|?"']+$/i;
export const BANNER_NAME = /^[^\[\]\\`~!#$^&*()+={};:<>|\/?,"'\s]+$/;
export const FLAT = /^[+]?\d+([.]\d+)?$/i;
export const COMMISSION_PLAN_NAME =
  /^[a-z0-9\u0041-\uFFDC][a-z0-9\u0041-\uFFDC\s][^`!~@#^&*()_+=;:'"|\\{}\[\].<>/?\-]{2,38}$/i;
export const POSITIVE_NEGATIVE_FLOATING =
  /^[1-9]{1}([0-9]{0,11})$|^[1-9]{1}[0-9]{0,11}?\.[0-9]{1,2}$|^0\.[0-9]{1,2}$|(^\-{1}([1-9]{1}([0-9]{0,11})$|[1-9]{1}[0-9]{0,11}?\.[0-9]{1,2}$|0\.[0-9]{1,2}$))/i;
export const DEPOSIT_MONEY = /^[1-9]{1}([0-9]{0,7})$|^[1-9]{1}[0-9]{0,7}?\.[0-9]{1,2}$|^0\.[0-9]{1,2}$/i;
export const AMOUNT_MONEY = /^[1-9]{1}([0-9]{0,11})$|^[1-9]{1}[0-9]{0,11}?\.[0-9]{1,2}$|^0\.[0-9]{1,2}$/i;
export const USER_NAME = /^[^\[\]\\`~!#$%^&*()={};:<>|\/?,"\'\s]{2,50}$/;
export const MIDDLE_NAME = /^(?!-)(?!.*--)[^0-9\[\]\\`~!@#$%^&*()_+={};:<>|.\/?,"']+$/i;
export const IS_NOT_REQUIRED_NAME =
  /^(?!-)(?!.*--)[^0-9\[\]\\`~!@#$%^&*()_+={};:<>|.°–—•€£¥₩₹¢§“”“»«…¿¡‰≈≠`”\/?,"']+$/i;
export const IS_NOT_REQUIRED_LAST_NAME =
  /^(?!-)(?!.*--)[^0-9\[\]\\`~!@#$%^&*()_+={};:<>|.°–—•€£¥₩₹¢§“”“»«…¿¡‰≈≠`”\/?,"']+$/i;
export const IS_NOT_REQUIRED_EMAIL = /^([a-z0-9_\.-]+)@([\da-z0-9\.-]+)\.([a-z\.]{2,6})$/i;
export const BET_LIMIT = /^(?=.+)(?:[1-9]\d*|0)?(?:\.[0-9]?[0-9])?$/i;
export const IS_ANDROID = /Android/i;
export const IS_OPERA = /Opera Mini/i;
export const IS_WINDOWS = /IEMobile/i;
export const IS_IOS = /iPhone|iPad|iPod/i;
export const IS_BLACK_BERRY = /BlackBerry/i;
export const NUMBER_FORMAT = /(\d)(?=(\d{3})+(?!\d))/g;
export const SPLIT_TAGS = /<\/?[^>]+(>|$)/g;
export const REPLACE_COMMA = /,/g;
export const REPLACE_NEW_LINE = /\n/g;
export const CHECK_IS_NUMBER = /^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?$/;
export const MARKETING_NAMES = /^(?!-)(?!.*--)[^\[\]\\`~!@#$%^&*()+={};:<>|.\/?,"']+$/i;
export const MARKETING_WEBSITE =
  /^(((?!-))(xn--)?[a-z0-9-_]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30})\.[a-z]{2,}$/i;
export const POSITIVE_NUMBER = /^((0\.\d*[1-9]+)|([1-9][0-9]*(\.\d*[1-9]+)?))$/i;
export const POSTBACK_PARAM_NAME = /^[a-zA-Z0-9-_]+$/i;
export const CPF_NUMBER = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
export const SHORT_LINK = /^[a-z0-9$\-_.+!*'()]+$/;
export const MARKETPLACE_NAME = /^[A-Za-z0-9\s-]+$/;
export const DECIMAL = /^-?\d+(\.\d+)?$/;
export const INTEGER = /^-?\d+$/;
export const NON_NEGATIVE_INTEGER = /^[0-9]+$/;
