import * as apiServices from './apiServices';

export const LOGIN = apiServices.USER_SERVICE + 'signIn';
export const LOGOUT = apiServices.USER_SERVICE + 'signOut';
export const SIGNIN_TWO_FACTOR_AUTH = apiServices.USER_SERVICE + 'signInTwoFactorAuth';
export const RESET_TWO_FACTOR_AUTH_SECRET = apiServices.AFFILIATE_SERVICE + 'resetTwoFactorAuthSecret';
export const REGISTRATION = apiServices.USER_SERVICE + 'registration';
export const LOGIN_US = apiServices.USER_SERVICE + 'become-affiliate';
export const GET_CURRENT_USER_INFO = apiServices.USER_SERVICE + 'get-current-user-info';
export const GET_USER_INFO = apiServices.USER_SERVICE + 'getUserInfo';
export const CONFIRM_AFFILIATE_VERIFICATION_EMAIL = apiServices.USER_SERVICE + 'confirmAffiliateVerifiedMail';
export const CHANGE_PASSWORD = apiServices.USER_SERVICE + 'change-password';
export const CHANGE_LANGUAGE = apiServices.USER_SERVICE + 'change-language';
export const BACK_TO_MAIN_ROLE = apiServices.USER_SERVICE + 'backToMainRole';
export const FORGOT_PASSWORD = apiServices.USER_SERVICE + 'forgot-password';
export const RESTORE_PASSWORD = apiServices.USER_SERVICE + 'restore-password';
export const CHANGE_ONE_TIME_PASSWORD = apiServices.USER_SERVICE + 'changeOneTimePassword';
export const CHANGE_PASSWORD_ACCOUNT = apiServices.USER_SERVICE + 'changePassword';

export const SEND_SMS_VERIFICATION_CODE = apiServices.USER_SERVICE + 'sendSmsVerificationCode';
export const PHONE_NUMBER_VERIFICATION_CODE = apiServices.USER_SERVICE + 'phoneNumberVerificationCode';

export const RESEND_TOKEN = apiServices.USER_SERVICE + 'resendToken';
export const RESEND_PASSWORD_MAIL = apiServices.USER_SERVICE + 'resendPassword';
export const RESEND_VERIFICATION_MAIL = apiServices.USER_SERVICE + 'resendVerificationEmail';
export const VERIFY_EMAIL = apiServices.USER_SERVICE + 'verifyEmail';
export const CHANGE_EMAIL = apiServices.USER_SERVICE + 'changeEmail';
export const GET_REGISTRATION_FIELDS = apiServices.USER_SERVICE + 'getRegistrationFields';
export const GET_ALL_REGISTRATION_FIELDS = apiServices.USER_SERVICE + 'getAllRegistrationFields';
export const SAVE_REGISTRATION_FIELDS = apiServices.USER_SERVICE + 'saveRegistrationFields';
export const CHANGE_USER_UTC_TIME_ZONE = apiServices.USER_SERVICE + 'changeUserUtcTimezone';

export const BONUS_TO_ADMIN = apiServices.AGENT_SERVICE + 'bonusToAdmin';
export const GET_AGENT_WALLET = apiServices.AGENT_SERVICE + 'getAgentWallet';
export const GET_AGENT_ALL_WALLETS = apiServices.AGENT_SERVICE + 'getAgentAllWallets';
export const CHANGE_AGENT_PASSWORD = apiServices.AGENT_SERVICE + 'changeAgentPassword';
export const CHANGE_REPORTING_CURRENCY = apiServices.AFFILIATE_SERVICE + 'changeReportCurrency';
export const GET_AGENT_AVAILABLE_CURRENCIES = apiServices.AGENT_SERVICE + 'getAgentAvailableCurrencies';
export const CORRECTION_TO_ADMIN = apiServices.AGENT_SERVICE + 'correctionToAdmin';
export const GET_CHILDREN = apiServices.AGENT_SERVICE + 'getChildren';
export const DEPOSIT_TO_AGENT = apiServices.AGENT_SERVICE + 'depositToAgent';
export const GET_AGENT_WALLET_BY_AGENT_ID = apiServices.AGENT_SERVICE + 'getAgentWalletByAgentId';
export const GET_AGENT_PARENT_IDS_WITH_ROLES = apiServices.AGENT_SERVICE + 'getAgentParentIdsWithRoles';
export const WITHDRAW_FROM_AGENT = apiServices.AGENT_SERVICE + 'withdrawFromAgent';
export const BONUS_TO_AGENT = apiServices.AGENT_SERVICE + 'bonusToAgent';
export const GET_AGENT_WALLET_INFO_AFTER_TRANSACTION = apiServices.AGENT_SERVICE + 'getAgentWalletInfoAfterTransaction';
export const GET_PLAYER_WALLET_INFO_AFTER_TRANSACTION =
  apiServices.AGENT_SERVICE + 'getPlayerWalletInfoAfterTransaction';
export const GET_CHILDREN_FILTERED_BY_ROLE = apiServices.AGENT_SERVICE + 'getChildrenFilteredByRole';
export const AGENT_CHANGE_PASSWORD = apiServices.AGENT_SERVICE + 'changeAgentPassword';
export const GET_AGENT_TRANSACTION_LIST = apiServices.AGENT_SERVICE + 'getAgentTransactionList';
export const GET_ALL_POSITIVE_AVAILABLE_WALLETS = apiServices.AGENT_SERVICE + 'getAllPositiveAvailableWallets';
export const UPLOAD_PAYOUT_LIST = apiServices.AGENT_SERVICE + 'uploadPayoutList';
export const GET_AVAILABLE_WALLETS_FROM_UPLOADED_LIST =
  apiServices.AGENT_SERVICE + 'getAvailableWalletsFromUploadedList';

export const SET_CONTACT_MAIL = apiServices.AFFILIATE_SERVICE + 'sendUsMail';
export const MOVE_AFFILIATE = apiServices.AFFILIATE_SERVICE + 'moveAffiliate';
export const UPDATE_USER_INFO = apiServices.AFFILIATE_SERVICE + 'updateUserInfo';
export const REMOVE_AFFILIATE = apiServices.AFFILIATE_SERVICE + 'removeAffiliate';
export const ADD_AFFILIATE_NOTE = apiServices.AFFILIATE_SERVICE + 'addAffiliateNote';
export const GET_AFFILIATES_LIST = apiServices.AFFILIATE_SERVICE + 'getAffiliateList';
export const GET_AFFILIATE_IP_LOG = apiServices.AFFILIATE_SERVICE + 'getAffiliateIpLog';
export const UPDATE_AFFILIATE_ROLE = apiServices.AFFILIATE_SERVICE + 'changeAffiliateRole';
export const UPDATE_AVAILABLE_WALLET = apiServices.AFFILIATE_SERVICE + 'updateAvailableWallet';
export const UPDATE_AFFILIATE_STATUS = apiServices.AFFILIATE_SERVICE + 'changeAffiliateStatus';
export const UPDATE_CURRENT_USER_INFO = apiServices.AFFILIATE_SERVICE + 'updateCurrentUserInfo';
export const GET_AFFILIATES_SHORT_INFO = apiServices.AFFILIATE_SERVICE + 'getAffiliateOtherDetails';
export const SET_AFFILIATES_STATUS_BULK = apiServices.AFFILIATE_SERVICE + 'setAffiliateStatusBulk';
export const GET_PARTNER_ALL_AFFILIATES = apiServices.AFFILIATE_SERVICE + 'getPartnerAllAffiliates';
export const CHANGE_FILTERED_DATA_STATUS = apiServices.AFFILIATE_SERVICE + 'changeFilteredDataStatus';
export const GET_DELETED_AFFILIATES_LIST = apiServices.AFFILIATE_SERVICE + 'getDeletedAffiliatesList';
export const GET_AFFILIATES_BY_HIERARCHIES = apiServices.AFFILIATE_SERVICE + 'getAffiliatesByHierarchies';
export const GET_ADMIN_STATUS_FOR_SEND_EMAIL = apiServices.AFFILIATE_SERVICE + 'getStatusAdminForSendEmail';
export const CHANGE_STATUS_ADMIN_FOR_SEND_EMAIL = apiServices.AFFILIATE_SERVICE + 'changeStatusAdminForSendEmail';
export const GET_AFFILIATE_CHANGE_STATUS_LOGS = apiServices.AFFILIATE_SERVICE + 'getAffiliatesChangeStatusLogs';
export const SAVE_VERIFICATION_DOCUMENT = apiServices.AFFILIATE_SERVICE + 'setVerificationDoc';
export const UPLOAD_STATUS_CHANGE_LIST_BY_FILE = apiServices.AFFILIATE_SERVICE + 'uploadStatusChangeListByFile';
export const GET_UPLOADED_STATUS_CHANGE_DATA_LIST = apiServices.AFFILIATE_SERVICE + 'getUploadedStatusChangeDataList';
export const SET_AFFILIATE_STATUS_BY_FILE = apiServices.AFFILIATE_SERVICE + 'setAffiliateStatusByFile';
export const GET_PARTNER_ALL_ACTIVE_AFFILIATES = apiServices.AFFILIATE_SERVICE + 'getPartnerAllActiveAffiliates';
export const GET_USERS_LIST = apiServices.AFFILIATE_SERVICE + 'getUsersList';
export const GET_AFFILIATE_PASSWORD_CHANGE_LOG = apiServices.AFFILIATE_SERVICE + 'getAffiliatePasswordChangeLog ';
export const GET_AFFILIATE_PERSONAL_INFO_LOG = apiServices.AFFILIATE_SERVICE + 'getAffiliatePersonalInfoLog';
export const UPLOAD_CORRECTION_LIST_BY_FILE = apiServices.AFFILIATE_SERVICE + 'uploadCorrectionListByFile';
export const GET_UPLOADED_CORRECTION_LIST = apiServices.AFFILIATE_SERVICE + 'getUploadedCorrectionList';
export const UPDATE_AVAILABLE_WALLETS_BY_FILE = apiServices.AFFILIATE_SERVICE + 'updateAvailableWalletsByFile';

export const GET_APP_SETUP = apiServices.CORE_SERVICE + 'getData';
export const CALCULATE_NOW = apiServices.CORE_SERVICE + 'calculateNow';
export const LAST_CALCULATE_DATE = apiServices.CORE_SERVICE + 'getPartnerLastCalculationDate';
export const GET_GENERATED_TOKEN = apiServices.CORE_SERVICE + 'getGeneratedToken';
export const SIGN_IN_FROM_BET_CONSTRUCT = apiServices.CORE_SERVICE + 'signInFromBetconstruct';

export const GET_HELP = apiServices.DICTIONARY_SERVICE + 'getHelp';
export const DELETE_HELP = apiServices.DICTIONARY_SERVICE + 'deleteHelp';
export const SAVE_HELP = apiServices.DICTIONARY_SERVICE + 'saveHelpLink';
export const SAVE_HELP_DOCUMENT = apiServices.DICTIONARY_SERVICE + 'saveHelpDocument';
export const GET_CURRENT_LANG_DICTIONARY_ITEM = apiServices.DICTIONARY_SERVICE + 'getCurrentLangDictionaryItem';
export const GET_TESTIMONIALS = apiServices.DICTIONARY_SERVICE + 'getTestimonials';
export const SAVE_TESTIMONIALS = apiServices.DICTIONARY_SERVICE + 'saveTestimonials';
export const DELETE_TESTIMONIALS = apiServices.DICTIONARY_SERVICE + 'deleteTestimonials';
export const DND_TESTIMONIALS = apiServices.DICTIONARY_SERVICE + 'dragAndDropTestimonials';
export const GET_DICTIONARY_ITEM = apiServices.DICTIONARY_SERVICE + 'getDictionaryItem';
export const UPDATE_DICTIONARY = apiServices.DICTIONARY_SERVICE + 'updateDictionary';

export const GET_PAGE_FILTERS = apiServices.FILTER_SERVICE + 'getFiltersList';
export const SAVE_FILTER = apiServices.FILTER_SERVICE + 'saveFilter';
export const REMOVE_FILTER = apiServices.FILTER_SERVICE + 'removeFilter';

export const ADD_TO_GROUP = apiServices.GROUP_SERVICE + 'addToGroup';
export const DELETE_GROUP = apiServices.GROUP_SERVICE + 'deleteGroup';
export const CREATE_GROUP = apiServices.GROUP_SERVICE + 'createGroup';
export const GET_GROUPS_LIST = apiServices.GROUP_SERVICE + 'getGroupsList';
export const UPDATE_GROUP_DETAILS = apiServices.GROUP_SERVICE + 'updateGroupDetails';
export const DELETE_AFFILIATE_FROM_GROUP = apiServices.GROUP_SERVICE + 'deleteAffiliateFromGroup';
export const CHANGE_GROUP_COMMISSION_PLAN = apiServices.GROUP_SERVICE + 'changeGroupCommissionPlan';
export const GIVE_HYBRID_TO_GROUP_AFFILIATE = apiServices.GROUP_SERVICE + 'giveHybridToGroupAffiliates';
export const GIVE_CPA_TO_GROUP_AFFILIATE = apiServices.GROUP_SERVICE + 'giveCpaToGroupAffiliates';
export const GET_AFFILIATE_LIST_BY_GROUP = apiServices.GROUP_SERVICE + 'getAffiliatesByGroupAndAction';
export const GET_AFFILIATES_LIST_BY_GROUP_ID = apiServices.GROUP_SERVICE + 'getAffiliatesListByGroupId';
export const SET_AFFILIATE_LIST_FOR_GROUP = apiServices.GROUP_SERVICE + 'setAffiliatesForGroupAndAction';

export const GET_BET_ODDS = apiServices.STATISTIC_SERVICE + 'getBetOdds';
export const GET_PLAYERS_BETS = apiServices.STATISTIC_SERVICE + 'getPlayersBets';
export const GET_ADDED_PLAYERS_LIST = apiServices.STATISTIC_SERVICE + 'getAddedPlayers';
export const GET_SUB_AGENT_REPORT = apiServices.STATISTIC_SERVICE + 'getSubAgentReport';
export const GET_AGENTS_TRANSFERS = apiServices.STATISTIC_SERVICE + 'getAgentsTransfers';
export const GET_PLAYERS_TRANSFERS = apiServices.STATISTIC_SERVICE + 'getPlayersTransfers';
export const GET_PRODUCT_REPORT_LIST = apiServices.STATISTIC_SERVICE + 'getProductReport';
export const GET_REFERRAL_REPORT_LIST = apiServices.STATISTIC_SERVICE + 'getReferralReport';
export const GET_CALCULATION_LOG_LIST = apiServices.STATISTIC_SERVICE + 'getCalculationLog';
export const GET_AFFILIATE_TOTAL_NGR = apiServices.STATISTIC_SERVICE + 'getAffiliateTotalNgr';
export const GET_DELETED_PLAYERS_LIST = apiServices.STATISTIC_SERVICE + 'getDeletedPlayerLog';
export const GET_SUB_AGENT_STATISTIC = apiServices.STATISTIC_SERVICE + 'getSubAgentStatistics';
export const GET_MEDIA_STATISTICS_PRO = apiServices.STATISTIC_SERVICE + 'getMediaStatisticsPro';
export const GET_ACTIVITY_INFO_BY_META = apiServices.STATISTIC_SERVICE + 'getActivityInfoByMeta';
export const GET_LINK_META_TAGS_REPORT = apiServices.STATISTIC_SERVICE + 'getLinkMetaTagsReport';
export const GET_PRODUCT_STATISTICS_LIST = apiServices.STATISTIC_SERVICE + 'getProductStatistics';
export const GET_PRODUCT_STATISTICS_SUB = apiServices.STATISTIC_SERVICE + 'getProductStatisticsSub';
export const GET_COMPANY_VIEW_STATISTICS = apiServices.STATISTIC_SERVICE + 'getCompanyViewStatistics';
export const GET_SUB_AFFILIATE_STATISTIC = apiServices.STATISTIC_SERVICE + 'getSubAffiliateStatistics';
export const GET_PLAYERS_STATISTICS_PRO_LIST = apiServices.STATISTIC_SERVICE + 'getPlayersStatisticsPro';
export const GET_CORRECTION_LOG_STATISTICS = apiServices.STATISTIC_SERVICE + 'getCorrectionLogStatistics';
export const GET_PRODUCT_STATISTICS_DAILY_LIST = apiServices.STATISTIC_SERVICE + 'getProductStatisticsDaily';
export const GET_PRODUCT_STATISTICS_DAILY_SUB = apiServices.STATISTIC_SERVICE + 'getProductStatisticsDailySub';
export const GET_PLAYERS_LINKS_STATISTICS_LIST = apiServices.STATISTIC_SERVICE + 'getPlayersLinksStatistics';
export const GET_PRODUCT_PLAYER_STATISTICS_LIST = apiServices.STATISTIC_SERVICE + 'getProductPlayerStatistics';
export const GET_ACTIVITY_INFO_BY_LINKS_STATISTICS = apiServices.STATISTIC_SERVICE + 'getActivityInfoByLinksStatistics';
export const GET_ACTIVITY_STATISTIC_BY_USERS_LIST = apiServices.STATISTIC_SERVICE + 'getActivityStatisticsByAffiliates';
export const GET_PLAYERS_LINKS_STATISTICS_DAILY_LIST = apiServices.STATISTIC_SERVICE + 'getPlayersLinksStatisticsDaily';
export const GET_PRODUCT_STATISTICS_BY_AFFILIATE_LIST =
  apiServices.STATISTIC_SERVICE + 'getProductStatisticsByAffiliate';
export const GET_PRODUCT_STATISTICS_BY_AFFILIATE_SUB =
  apiServices.STATISTIC_SERVICE + 'getProductStatisticsByAffiliateSub';
export const GET_SUB_AGENTS_STATISTICS_FOR_EXPORT_ALL =
  apiServices.STATISTIC_SERVICE + 'getSubAgentStatisticsForExportAll';

export const GET_BONUSES = apiServices.PLAYER_SERVICE + 'getBonuses';
export const DELETE_PLAYER = apiServices.PLAYER_SERVICE + 'deletePlayer';
export const RESTORE_PLAYER = apiServices.PLAYER_SERVICE + 'restorePlayer';
export const ADD_PLAYER_TOTE = apiServices.PLAYER_SERVICE + 'addPlayerNote';
export const TRANSFER_PLAYER = apiServices.PLAYER_SERVICE + 'transferPlayer';
export const REGISTER_PLAYER = apiServices.PLAYER_SERVICE + 'registerPlayer';
export const GET_PLAYER_LIMIT = apiServices.PLAYER_SERVICE + 'getPlayerLimit';
export const SET_PLAYER_LIMIT = apiServices.PLAYER_SERVICE + 'setPlayerLimit';
export const DEPOSIT_TO_PLAYER = apiServices.PLAYER_SERVICE + 'depositToPlayer';
export const ADD_PLAYER_TO_BONUS = apiServices.PLAYER_SERVICE + 'addPlayerToBonus';
export const TRANSFER_PLAYER_LOG = apiServices.PLAYER_SERVICE + 'transferPlayerLog';
export const WITHDRAW_FROM_PLAYER = apiServices.PLAYER_SERVICE + 'withdrawFromPlayer';
export const TRANSFER_ALL_PLAYERS = apiServices.PLAYER_SERVICE + 'transferAllPlayers';
export const ADD_PLAYER_FROM_SPRING = apiServices.PLAYER_SERVICE + 'addPlayerFromSpring';
export const CHANGE_PLAYER_PASSWORD = apiServices.PLAYER_SERVICE + 'changePlayerPassword';
export const GET_CURRENT_AGENT_OWN_PLAYERS = apiServices.PLAYER_SERVICE + 'getCurrentAgentOwnPlayers';
export const GET_PLAYERS_FOR_CURRENT_AGENT = apiServices.PLAYER_SERVICE + 'getPlayersForCurrentAgent';
export const GET_PLAYERS_BALANCE_BY_ID = apiServices.PLAYER_SERVICE + 'getPlayerBalanceById';
export const GET_PLAYER_PASSWORD_CHANGE_LOG = apiServices.PLAYER_SERVICE + 'getPlayerPasswordChangeLog ';

export const GET_AGENT_BET_LIMIT = apiServices.AGENT_BET_LIMIT + 'getAgentBetLimit';
export const SET_AGENT_BET_LIMIT = apiServices.AGENT_BET_LIMIT + 'setAgentBetLimit';

export const GET_AFFILIATE_LIFE_TIME = apiServices.LIFE_TIME_SERVICE + 'getAffiliateLifeTime';
export const SET_AFFILIATE_LIFE_TIME = apiServices.LIFE_TIME_SERVICE + 'setAffiliateLifeTime';
export const GET_AFFILIATE_LIFE_TIME_LOG = apiServices.LIFE_TIME_SERVICE + 'getAffiliateLifeTimeLog';

export const GET_WITHDRAW_SUB_USER_LIST = apiServices.WITHDRAW_SERVICE + 'getWithdrawListForChildren';
export const GET_MY_REQUESTS_USER_LIST = apiServices.WITHDRAW_SERVICE + 'getWithdrawList';
export const CHANGE_WITHDRAW_STATUS = apiServices.WITHDRAW_SERVICE + 'changeWithdrawStatus';
export const SAVE_WITHDRAW = apiServices.WITHDRAW_SERVICE + 'saveWithdraw';
export const GET_WITHDRAW_LIST_FOR_CHILDREN = apiServices.WITHDRAW_SERVICE + 'getWithdrawListForChildrenLog';
export const GET_WITHDRAW_LIST_LOG = apiServices.WITHDRAW_SERVICE + 'getWithdrawListLog';

export const GET_AFFILIATE_FLAT_FEE = apiServices.COST_SERVICE + 'getAffiliatesFlatFee';
export const SET_AFFILIATE_FLAT_FEE = apiServices.COST_SERVICE + 'setAffiliatesFlatFee';
export const GET_ADMINISTRATIVE_COSTS = apiServices.COST_SERVICE + 'getAdministrativeCost';
export const GET_BONUS_COSTS = apiServices.COST_SERVICE + 'getBonusCost';
export const SET_ADMINISTRATIVE_COSTS = apiServices.COST_SERVICE + 'setAdministrativeCost';
export const SET_CONVERTED_BONUS_COST = apiServices.COST_SERVICE + 'setConvertedBonusCost';
export const SET_BONUS_COST = apiServices.COST_SERVICE + 'setBonusCost';
export const GET_PARTNER_BONUS_COST = apiServices.COST_SERVICE + 'getPartnerBonusCostsPerProduct';
export const GET_PARTNER_TAXES = apiServices.COST_SERVICE + 'getPartnerTaxes';
export const GET_TAXES_LIST = apiServices.COST_SERVICE + 'getTaxesList';
export const GET_PARTNER_TAXES_CONFIG_LOGS = apiServices.COST_SERVICE + 'getPartnerTaxesConfigLogs';

export const GET_AFFILIATE_TAXES = apiServices.COST_SERVICE + 'getAffiliateTaxes';
export const SET_TAXES = apiServices.COST_SERVICE + 'setTaxes';
export const SET_PARNTER_TAXES = apiServices.COST_SERVICE + 'setPartnerTaxes';
export const GET_TAXES_BY_GROUP = apiServices.COST_SERVICE + 'getTaxesbyGroup';
export const SET_TAXES_BY_GROUP = apiServices.COST_SERVICE + 'setTaxesByGroup';
export const GET_CONVERTED_BONUS_BY_GROUP = apiServices.COST_SERVICE + 'getConvertedBonusCostByGroup';
export const SET_CONVERTED_BONUS_BY_GROUP = apiServices.COST_SERVICE + 'setConvertedBonusCostByGroup';
export const GET_PRODUCTS_COST_LOG = apiServices.COST_SERVICE + 'getProductsCostLog';

export const ADD_PAYMENT_SYSTEM = apiServices.PAYMENT_SERVICE + 'addPaymentSystem';
export const GET_PAYMENT_MIN_MAX_RANGE = apiServices.PAYMENT_SERVICE + 'getPaymentMinMaxRange';
export const EDIT_PAYMENT_MIN_MAX_RANGE = apiServices.PAYMENT_SERVICE + 'editPaymentMinMaxRange';
export const GET_AFFILIATE_PAYMENT_SYSTEMS = apiServices.PAYMENT_SERVICE + 'getAffiliatePaymentSystems';
export const DELETE_PARTNER_PAYMENT_SYSTEM = apiServices.PAYMENT_SERVICE + 'deletePartnerPaymentSystem';
export const GET_AFFILIATE_DEFAULT_WITHDRAW = apiServices.PAYMENT_SERVICE + 'getAffiliateDefaultWithdraw';
export const ADD_AFFILIATE_DEFAULT_WITHDRAW = apiServices.PAYMENT_SERVICE + 'addAffiliateDefaultWithdraw';
export const CHANGE_AFFILIATE_PAYMENT_LIST_STATUS = apiServices.PAYMENT_SERVICE + 'changeAffiliatePaymentListStatus';
export const GET_PAYMENT_SYSTEMS_FOR_CONTRIBUTION_COSTS =
  apiServices.PAYMENT_SERVICE + 'getPaymentSystemsForContributionCosts';
export const EDIT_PAYMENT_SYSTEMS_FOR_CONTRIBUTION_COSTS =
  apiServices.PAYMENT_SERVICE + 'updatePaymentSystemsContributionCosts';
export const GET_PAYMENT_FTD_RESTRICTION = apiServices.PAYMENT_SERVICE + 'getPaymentFtdRestriction';
export const SET_PAYMENT_FTD_RESTRICTION = apiServices.PAYMENT_SERVICE + 'setPaymentFtdRestriction';

export const UPDATE_FILE_UPLOADER_NAME = apiServices.PAYMENT_SERVICE + 'updateFileUploaderName';

export const GET_AVAILABLE_BANNERS = apiServices.BANNERS_SERVICE + 'getAvailableBanners';
export const ACTIVATE_BANNER = apiServices.BANNERS_SERVICE + 'activateBanner';
export const GET_ACTIVATE_BANNERS = apiServices.BANNERS_SERVICE + 'getActiveBanners';
export const EDIT_BANNER = apiServices.BANNERS_SERVICE + 'editBanner';
export const GET_DELETED_BANNERS = apiServices.BANNERS_SERVICE + 'getDeletedBanners';
export const UPLOAD_BANNER = apiServices.BANNERS_SERVICE + 'uploadBanner';
export const CHANGE_BANNER = apiServices.BANNERS_SERVICE + 'changeBanner';
export const MAKE_FAVORITE = apiServices.BANNERS_SERVICE + 'makeFavorite';
export const DELETE_BANNER = apiServices.BANNERS_SERVICE + 'deleteBanner';
export const GET_MY_BANNERS = apiServices.BANNERS_SERVICE + 'getMyMedia';
export const GET_FAVORITE_BANNERS = apiServices.BANNERS_SERVICE + 'getFavoriteBanners';
export const GET_SUB_USER_BANNERS = apiServices.BANNERS_SERVICE + 'getChildBanners';
export const GET_ACTIVE_BANNERS = apiServices.BANNERS_SERVICE + 'getActiveBanners';
export const CHANGE_BANNER_STATUS = apiServices.BANNERS_SERVICE + 'changeBannerStatus';
export const CREATE_SCRIPT_FOR_BANNERS = apiServices.BANNERS_SERVICE + 'createScriptForBanners';
export const EDIT_LINK = apiServices.LINK_CREATOR + 'editLink';
export const GET_LINKS = apiServices.LINK_CREATOR + 'getLinks';
export const DELETE_LINK = apiServices.LINK_CREATOR + 'deleteLink';
export const CREATE_LINK = apiServices.LINK_CREATOR + 'createLink';
export const GET_DOMAINS_URL = apiServices.LINK_CREATOR + 'getDomains';
export const EDIT_LINK_FROM_LIST = apiServices.LINK_CREATOR + 'editLinkFromList';
export const ADD_LANDING_PAGE = apiServices.LINK_CREATOR + 'addLandingPage';
export const EDIT_LANDING_PAGE = apiServices.LINK_CREATOR + 'editLandingPage';
export const GET_LANDING_PAGES_LIST = apiServices.LINK_CREATOR + 'getLandingPageList';
export const REMOVE_LANDING_PAGE = apiServices.LINK_CREATOR + 'removeLandingPage';
export const GET_MARKETING_SOURCES = apiServices.LINK_CREATOR + 'getMarketingSources';
export const ADD_MARKETING_SOURCE = apiServices.LINK_CREATOR + 'addMarketingSource';
export const EDIT_MARKETING_SOURCE = apiServices.LINK_CREATOR + 'editMarketingSource';
export const REMOVE_MARKETING_SOURCE = apiServices.LINK_CREATOR + 'removeMarketingSource';
export const GET_URL_LIST_BY_AFFILIATE = apiServices.LINK_CREATOR + 'getUrlListByAffiliate';
export const GET_ADDITIONAL_DOMAINS = apiServices.LINK_CREATOR + 'getPartnerAdditionalDomains';
export const SET_ADDITIONAL_DOMAIN_FOR_AFFILIATE = apiServices.LINK_CREATOR + 'setAdditionalDomainForAffiliate';
export const GET_LANDING_PAGE_LIST_FOR_AFFILIATE = apiServices.LINK_CREATOR + 'getLandingPageListForAffiliates';

export const GET_LANGUAGE_RESOURCES = apiServices.LANGUAGE_RESOURCES_SERVICE + 'translationsReact.js?v=1';

export const GET_WIDGETS_DATA = apiServices.WIDGETS_SERVICE + 'getWidgetsData';
export const GET_CHARTS_DATA = apiServices.WIDGETS_SERVICE + 'getChartsList';
export const GET_WIDGETS_DATA_AGENT = apiServices.AGENT_WIDGETS + 'getWidgetsData';
export const GET_CHARTS_DATA_AGENT = apiServices.AGENT_WIDGETS + 'getChartsList';

export const CONFIGURATION_SAVE = apiServices.CONFIGURATION_SERVICE + 'save';
export const GET_DOMAINS = apiServices.CONFIGURATION_SERVICE + 'getDomainNames';
export const GET_ALL_PRODUCTS = apiServices.CONFIGURATION_SERVICE + 'get-all-products';
export const GET_All_LANGUAGES = apiServices.CONFIGURATION_SERVICE + 'get-all-languages';
export const GET_MAIL_TEMPLATES = apiServices.CONFIGURATION_SERVICE + 'get-mail-template';
export const GET_SKIN_CURRENCIES = apiServices.CONFIGURATION_SERVICE + 'getSkinCurrencies';
export const GET_PARTNER_BONUSES = apiServices.CONFIGURATION_SERVICE + 'getPartnerBonuses';
export const GET_SOCIAL_LINKS_LIST = apiServices.CONFIGURATION_SERVICE + 'getSocialLinks';
export const GET_ALL_CURRENCIES = apiServices.CONFIGURATION_SERVICE + 'getAllCurrenciesPro';
export const GET_DYNAMIC_VARIABLES = apiServices.CONFIGURATION_SERVICE + 'getDynamicVariables';
export const EDIT_PARTNER_PRODUCTS = apiServices.CONFIGURATION_SERVICE + 'editPartnerProducts';
export const UPDATE_PARTNER_BONUSES = apiServices.CONFIGURATION_SERVICE + 'updatePartnerBonuses';
export const CHANGE_PARTNER_BONUSES = apiServices.CONFIGURATION_SERVICE + 'changePartnerBonuses';
export const EDIT_PARTNER_CURRENCIES = apiServices.CONFIGURATION_SERVICE + 'editPartnerCurrencies';
export const CHANGE_DEFAULT_COMMISSION_PLAN = apiServices.CONFIGURATION_SERVICE + 'changeDefaultCommissionPlan';
export const SET_PARTNER_ADDITIONAL_DOMAINS = apiServices.CONFIGURATION_SERVICE + 'setPartnerAdditionalDomains';
export const DELETE_PARTNER_ADDITIONAL_DOMAINS = apiServices.CONFIGURATION_SERVICE + 'deletePartnerAdditionalDomains';
export const GET_PRODUCTS_LOG = apiServices.CONFIGURATION_SERVICE + 'getPartnerProductsLogs';

export const GET_CARRY_OVER_LOG_LIST = apiServices.CARRY_OVER_SERVICE + 'getCarryOverLogByAffiliate';
export const GET_AFFILIATE_CARRY_OVER = apiServices.CARRY_OVER_SERVICE + 'getAffiliateCarryOver';
export const GET_PARTNER_CARRY_OVER = apiServices.CARRY_OVER_SERVICE + 'getPartnerCarryOver';
export const SET_AFFILIATE_CARRY_OVER = apiServices.CARRY_OVER_SERVICE + 'setAffiliateCarryOver';
export const SET_PARTNER_CARRY_OVER = apiServices.CARRY_OVER_SERVICE + 'setPartnerCarryOver';
export const SET_GROUP_CARRY_OVER = apiServices.CARRY_OVER_SERVICE + 'setGroupCarryOver';

export const GET_COUNTRY_BY_COUNTRY_LIST = apiServices.REPORT_SERVICE + 'getCountryByCountryReport';
export const GET_MONTH_BY_MONTH_REPORT_LIST = apiServices.REPORT_SERVICE + 'getMonthByMonthReport';
export const GET_REVENUE_BREAKDOWN_LIST = apiServices.REPORT_SERVICE + 'getRevenueBreakdownReport';
export const GET_MARKETING_SOURCE_MEDIA_REPORT = apiServices.REPORT_SERVICE + 'getMarketingSourcesMediaReport';
export const GET_MARKETING_SOURCE_LINKS_REPORT = apiServices.REPORT_SERVICE + 'getMarketingSourcesLinksReport';
export const GET_USER_PERFORMANCE_REPORT = apiServices.REPORT_SERVICE + 'getUserPerformanceReport';
export const GET_COHORT_ANALYTICS_REPORT = apiServices.REPORT_SERVICE + 'getCohortAnalyticsReport';

export const GET_EDITABLE_PERMISSIONS_BY_ROLE = apiServices.PERMISSIONS_SERVICE + 'getEditablePermissionsByRole';
export const GET_AFFILIATE_PERMISSIONS = apiServices.PERMISSIONS_SERVICE + 'getAffiliatePermissions';
export const CHANGE_PERMISSIONS_BY_ROLE = apiServices.PERMISSIONS_SERVICE + 'changePermissionsByRole';
export const CHANGE_PERMISSIONS_BY_AFFILIATE_ID = apiServices.PERMISSIONS_SERVICE + 'changePermissionsByAffiliateId';
export const GET_AVAILABLE_EDITABLE_PERMISSIONS_FOR_ROLE =
  apiServices.PERMISSIONS_SERVICE + 'getAvailableEditablePermissionsForRole';
export const SET_EDITABLE_PERMISSIONS_FOR_ROLE = apiServices.PERMISSIONS_SERVICE + 'setEditablePermissionsForRole';
export const SET_EDITABLE_PERMISSIONS_FOR_AFFILIATE =
  apiServices.PERMISSIONS_SERVICE + 'setEditablePermissionsForAffiliate';
export const GET_EDITABLE_PERMISSIONS_FOR_AFFILIATE =
  apiServices.PERMISSIONS_SERVICE + 'getAvailableEditablePermissionsForAffiliate';

export const GET_COMMISSIONS = apiServices.COMMISSION_SERVICE + 'getAllCommissionTypes';
export const DELETE_COMMISSION = apiServices.COMMISSION_SERVICE + 'deleteCommissionPlanType';
export const GET_COMMISSION_PLAN_LOG = apiServices.COMMISSION_SERVICE + 'getCommissionPlanLogs';
export const GET_COMMISSION_TYPE_BY_ID = apiServices.COMMISSION_SERVICE + 'getCommissionTypeById';
export const GET_AFFILIATE_COMMISSION = apiServices.COMMISSION_SERVICE + 'getAffiliateCommission';
export const SAVE_COMMISSION_PLAN_TYPE = apiServices.COMMISSION_SERVICE + 'saveCommissionPlanType';
export const CHANGE_COMMISSION_PLAN_TYPE = apiServices.COMMISSION_SERVICE + 'changeCommissionTypes';
export const GET_COMMISSION_TYPE_NAMES = apiServices.COMMISSION_SERVICE + 'getAllCommissionTypesNames';
export const CHANGE_AFFILIATE_COMMISSION_PLAN = apiServices.COMMISSION_SERVICE + 'changeAffiliateCommissionPlan';
export const GET_AFFILIATES_COMMISSION_PERCENTS = apiServices.COMMISSION_SERVICE + 'getAffiliatesCommissionPercents';
export const GET_AFFILIATE_COMMISSION_PLANS_BY_PRODUCT =
  apiServices.COMMISSION_SERVICE + 'getAffiliateCommissionPlansByProduct';
export const CHANGE_COMMISSION_ARCHIVED_STATUS = apiServices.COMMISSION_SERVICE + 'changeCommissionTypesArchivedStatus';

export const SET_CPA = apiServices.CPA_SERVICE + 'setCpa';
export const EDIT_CPA = apiServices.CPA_SERVICE + 'editCpa';
export const GET_CPA = apiServices.CPA_SERVICE + 'getAllCpa';
export const DELETE_CPA = apiServices.CPA_SERVICE + 'deleteCpa';
export const GET_CURRENT_CPA = apiServices.CPA_SERVICE + 'getCurrentCpa';
export const GIVE_CPA_TO_AFFILIATE = apiServices.CPA_SERVICE + 'giveCpaToAffiliate';

export const ADD_HYBRID = apiServices.HYBRID_SERVICE + 'addHybrid';
export const EDIT_HYBRID = apiServices.HYBRID_SERVICE + 'editHybrid';
export const GET_HYBRIDS = apiServices.HYBRID_SERVICE + 'getAllHybrids';
export const DELETE_HYBRIDS = apiServices.HYBRID_SERVICE + 'deleteHybrid';
export const GIVE_HYBRID_TO_AFFILIATE = apiServices.HYBRID_SERVICE + 'giveHybridToAffiliate';

export const GET_POST_BACK_LIST = apiServices.POST_BACK_SERVICE + 'getPostbackList';
export const GET_POST_BACK_BY_ID = apiServices.POST_BACK_SERVICE + 'getPostbackById';
export const SAVE_POST_BACK_PARAMS_LIST = apiServices.POST_BACK_SERVICE + 'savePostbackParams';
export const DELETE_POST_BACK_PARAMS_LIST = apiServices.POST_BACK_SERVICE + 'deletePostbackParamsList';
export const GET_CONFIG_FOR_POSTBACK = apiServices.POST_BACK_SERVICE + 'getConfigForPostback';
export const DELETE_POSTBACK_CONFIG_ELEMENTS = apiServices.POST_BACK_SERVICE + 'deletePostbackConfigElements';
export const ADD_POSTBACK_CONFIG_ELEMENTS = apiServices.POST_BACK_SERVICE + 'addPostbackConfigElements';
export const RESEND_DATA = apiServices.POST_BACK_SERVICE + 'resendData';
export const TEST_POST_BACK = apiServices.POST_BACK_SERVICE + 'testPostBack';

export const GET_REFERRAL = apiServices.REFERRAL_COMMISSION_SERVICE + 'getAllReferralCommissions';
export const DELETE_REFERRAL = apiServices.REFERRAL_COMMISSION_SERVICE + 'deleteReferralCommission';
export const SAVE_REFERRAL_COMMISSION = apiServices.REFERRAL_COMMISSION_SERVICE + 'saveReferralCommission';
export const EDIT_REFERRAL_COMMISSION = apiServices.REFERRAL_COMMISSION_SERVICE + 'editReferralCommission';
export const GET_AFFILIATE_REFERRAL_COMMISSION =
  apiServices.REFERRAL_COMMISSION_SERVICE + 'getAffiliateReferralCommission';
export const SET_AFFILIATE_REFERRAL_COMMISSION =
  apiServices.REFERRAL_COMMISSION_SERVICE + 'setAffiliateReferralCommission';

export const GET_AVAILABLE_LANGUAGES = apiServices.FEED_SERVICE_SERVICE + 'getAvailableLangusges';
export const GET_FEED_TOKEN = apiServices.FEED_SERVICE_SERVICE + 'getToken';

export const EDIT_SOCIAL_FOLLOW = apiServices.SOCIAL_SHARE_SERVICE + 'editSocialFollow';
export const GET_SOCIAL_SHARE_LIST = apiServices.SOCIAL_SHARE_SERVICE + 'getSocialShareList';
export const PUBLISH_NEWS = apiServices.SOCIAL_SHARE_SERVICE + 'publishNews';
export const SHARE_POST = apiServices.SOCIAL_SHARE_SERVICE + 'sharePost';
export const EDIT_POST = apiServices.SOCIAL_SHARE_SERVICE + 'editPost';
export const DELETE_SOCIAL_SHARE_BY_ID = apiServices.SOCIAL_SHARE_SERVICE + 'deleteSocialShareById';
export const GET_SOCIAL_SHARE_LANG_BY_SOCIAL_ID = apiServices.SOCIAL_SHARE_SERVICE + 'getSocialShareLangBySocialId';
export const GET_LATEST_NEWS = apiServices.SOCIAL_SHARE_SERVICE + 'getLatestNews';

export const SEND_MAIL_TO_GROUP = apiServices.MESSAGE_SERVICE + 'sendMailToGroup';
export const GET_AFFILIATES_FOR_MESSAGE_LIST = apiServices.MESSAGE_SERVICE + 'getAffiliatesForMessageList';
export const CHANGE_MESSAGE_STATUS_TO_READ = apiServices.MESSAGE_SERVICE + 'changeMessageStatusToRead';
export const CHANGE_MESSAGE_STATUS_TO_UNREAD = apiServices.MESSAGE_SERVICE + 'changeMessageStatusToUnRead';
export const GET_CHATS = apiServices.MESSAGE_SERVICE + 'getChats';
export const GET_SCHEDULED_CHATS = apiServices.MESSAGE_SERVICE + 'getScheduledChats';
export const GET_SCHEDULED_CHAT = apiServices.MESSAGE_SERVICE + 'getScheduledChat';
export const GET_CHAT = apiServices.MESSAGE_SERVICE + 'getChat';
export const GET_TOTAL_UNREAD_MESSAGES_COUNT = apiServices.MESSAGE_SERVICE + 'getTotalUnreadMessagesCount';
export const PIN_MESSAGE = apiServices.MESSAGE_SERVICE + 'pinMessage';
export const PIN_DELETE = apiServices.MESSAGE_SERVICE + 'deletePin';
export const DELETE_CHAT = apiServices.MESSAGE_SERVICE + 'deleteChat';
export const DELETE_MESSAGE = apiServices.MESSAGE_SERVICE + 'deleteMessage';
export const DELETE_SCHEDULED_CHAT = apiServices.MESSAGE_SERVICE + 'deleteScheduledChat';
export const SEND_SCHEDULED_MESSAGE = apiServices.MESSAGE_SERVICE + 'sendMessage';
export const CHANGE_MESSAGES_STATUS_FOR_CHAT = apiServices.MESSAGE_SERVICE + 'changeMessagesStatusForChat';
export const MARK_AS_READ_ALL = apiServices.MESSAGE_SERVICE + 'markAsReadAll';

export const GET_FOOTER = apiServices.NEWS_LETTER_SERVICE + 'getFooter';
export const SET_FOOTER = apiServices.NEWS_LETTER_SERVICE + 'setFooter';
export const SAVE_TEMPLATE = apiServices.NEWS_LETTER_SERVICE + 'saveTemplate';
export const GET_TEMPLATES = apiServices.NEWS_LETTER_SERVICE + 'getTemplates';
export const DELETE_TEMPLATE = apiServices.NEWS_LETTER_SERVICE + 'deleteTemplate';
export const SEND_NEWS_LETTER = apiServices.NEWS_LETTER_SERVICE + 'sendNewsLetter';

export const PAY_TO_AGENT = apiServices.WALLET_SERVICE + 'payToAgent';
export const GET_PLAYER_INVOICE = apiServices.WALLET_SERVICE + 'getPlayerInvoice';
export const GET_PLAYER_PAYOUT_LOG = apiServices.WALLET_SERVICE + 'getPlayerPayoutLog';
export const AGENT_WALLET_CORRECTION = apiServices.WALLET_SERVICE + 'agentWalletCorrection';
export const PAY_TO_ALL_AGENTS = apiServices.WALLET_SERVICE + 'payToAllAgents';
export const PAY_TO_MULTIPLE_AGENTS = apiServices.WALLET_SERVICE + 'payToMultipleAgents';
export const PAY_TO_UPLOADED_LIST = apiServices.WALLET_SERVICE + 'payToUploadedList';

export const GET_AGENT_BET_LIMIT_BET = apiServices.AGENT_BET_LIMIT + 'getAgentBetLimitLog';

export const GET_PARTNER_SKIN_CUSTOMIZATION = apiServices.VIEW_CONFIGURATION + 'getPartnerSkinCustomization';
export const SET_PARTNER_SKIN_CUSTOMIZATION = apiServices.VIEW_CONFIGURATION + 'setPartnerSkinCustomization';
export const SET_SKIN_LOGO = apiServices.VIEW_CONFIGURATION + 'setSkinLogo';
export const GET_LAYOUT_ELEMENTS = apiServices.VIEW_CONFIGURATION + 'getLayoutElements';
export const SAVE_LAYOUT_ELEMENT = apiServices.VIEW_CONFIGURATION + 'saveLayoutElement';

export const GET_SKINS_LIST = apiServices.DEPLOY + 'getSkinsList';
export const GET_POSTBACK_LOG = apiServices.POST_BACK_SERVICE + 'getPostbackLog';
export const GET_POSTBACK_CHANGE_LOG = apiServices.POST_BACK_SERVICE + 'getPostbackChangeLog';
export const GET_CONFIG = apiServices.DEPLOY + 'getConfig';
export const EDIT_CONFIG = apiServices.DEPLOY + 'editConfig';
export const ADD_BRAND = apiServices.DEPLOY + 'addBrand';
export const EDIT_BRAND = apiServices.DEPLOY + 'editBrand';
export const CREATE_PARTNER_ADMIN = apiServices.DEPLOY + 'createPartnerAdmin';
export const GET_SECONDARY_EMAIL = apiServices.DEPLOY + 'getSecondaryEmail';
export const SET_SECONDARY_EMAIL = apiServices.DEPLOY + 'setSecondaryEmail';
export const GET_PARTNER_SKIN_TYPE = apiServices.DEPLOY + 'getPartnerSkinType';
export const GET_PARTNER_PERMISSION_BY_ID = apiServices.DEPLOY + 'getPartnerPermissionById';
export const CHANGE_SKIN_STATUS = apiServices.DEPLOY + 'changeSkinStatus';
export const EDIT_PARTNER_PERMISSIONS = apiServices.DEPLOY + 'editPartnerPermissions';
export const RELEASE_SKIN = apiServices.DEPLOY + 'releaseSkin';
export const RELEASE_GLOBAL = apiServices.DEPLOY + 'releaseGlobal';
export const RELEASE_STAGING = apiServices.DEPLOY + 'releaseStaging';
export const RUN_NEW_SKIN = apiServices.DEPLOY + 'runNewSkin';
export const GET_DEPLOY_LOGS_REPORT = apiServices.DEPLOY + 'getDeployLogsReport';
export const GET_SKIN_STATUS_LOGS_REPORT = apiServices.DEPLOY + 'getSkinStatusLogsReport';
export const GET_PARTNER_REGISTRATION_FIELDS = apiServices.DEPLOY + 'getRegistrationFields';
export const GET_AFFILIATE_ACCESS_TOKENS_LIST = apiServices.DEPLOY + 'getAffiliateAccessTokensList';
export const SET_AFFILIATE_ACCESS_TOKEN = apiServices.DEPLOY + 'setAffiliateAccessToken';
export const REMOVE_AFFILIATE_ACCESS_TOKEN = apiServices.DEPLOY + 'removeAffiliateAccessToken';
export const GET_CRON_LIST = apiServices.DEPLOY + 'getCronList';
export const GET_PARTNER_BRANDS = apiServices.DEPLOY + 'getPartnerBrands';

export const GET_CURRENCY_LIST = apiServices.CURRENCY_SERVICE + 'getCurrencyList';

export const GET_LINK_SHORTENER = apiServices.LINK_CREATOR + 'getShortLinkByUrl';

export const ADD_PERMISSION = apiServices.PERMISSIONS_SERVICE + 'addPermission';
export const ADD_PERMISSION_FOR_ROLES = apiServices.PERMISSIONS_SERVICE + 'addPermissionForRoles';
export const REMOVE_PERMISSION = apiServices.DEPLOY + 'removePermission';
export const GET_ALL_ROLES = apiServices.DEPLOY + 'getAllRoles';
export const GET_PERMISSION_FOR_ROLES = apiServices.DEPLOY + 'getPermissionForRoles';
export const GET_PERMISSION_LIST = apiServices.DEPLOY + 'getPermissionsList';
export const GET_PERMISSION_EDITABLE = apiServices.DEPLOY + 'getPermissionEditable';
export const SET_PERMISSION_EDITABLE = apiServices.DEPLOY + 'setPermissionEditable';
export const GET_PERMISSION_FUNCTIONS = apiServices.DEPLOY + 'getPermissionsFunctions';
export const ADD_PERMISSION_FUNCTIONS = apiServices.DEPLOY + 'addPermissionsFunctions';
export const REMOVE_PERMISSION_FUNCTIONS = apiServices.DEPLOY + 'removePermissionsFunctions';
export const GET_PERMISSION_ROLE_TYPES = apiServices.DEPLOY + 'getPermissionsRolesTypes';
export const ADD_PERMISSION_ROLE_TYPES = apiServices.DEPLOY + 'addPermissionsRolesTypes';
export const REMOVE_PERMISSION_ROLE_TYPES = apiServices.DEPLOY + 'removePermissionsRolesTypes';
export const REPEAT_CRON = apiServices.DEPLOY + 'repeatCron';
export const UPDATE_LANGUAGE_TRANSLATIONS = apiServices.DEPLOY + 'updateLanguageTranslations';
export const GET_PARTNER_REPORT = apiServices.DEPLOY + 'getPartnerReport';
export const UPDATE_PARTNER_REPORT = apiServices.DEPLOY + 'updatePartnerReport';
export const GET_SECONDARY_PARTNERS_LIST = apiServices.DEPLOY + 'getSecondaryPartnersList';

export const SAVE_VERIFICATION_CONFIGS = apiServices.VERIFICATION_SERVICE + 'setVerificationConfigs';
export const GET_VERIFICATION_DOC = apiServices.AFFILIATE_SERVICE + 'getVerificationDoc';
export const GET_NOTIFICATIONS = apiServices.NOTIFICATION_SERVICE + 'getAllUserNotifications';
export const MARK_NOTIFICATIONS_AS_READ = apiServices.NOTIFICATION_SERVICE + 'markUserNotificationsAsRead';

export const GET_FILE_HUB_LIST = apiServices.FILE_HUB_SERVICE + 'getList';
export const GET_FILE_BY_ID = apiServices.FILE_HUB_SERVICE + 'getById';

export const GET_LOYALTY_USERS_LIST = apiServices.LOYALTY_USER_SERVICE + 'getLoyaltyUsersList';
export const CHANGE_LOYALTY_MARKETPLACE_STATUS = apiServices.LOYALTY_USER_SERVICE + 'changeMarketplaceStatus';
export const LOYALTY_MAKE_CORRECTION = apiServices.LOYALTY_USER_SERVICE + 'makeCorrection';
export const LOYALTY_CHANGE_STATUS = apiServices.LOYALTY_USER_SERVICE + 'changeStatus';
export const LOYALTY_GET_LEVELS_LIST = apiServices.LOYALTY_LEVEL_SERVICE + 'getLevelsList';
export const LOYALTY_GET_ITEMS = apiServices.LOYALTY_MARKETPLACE_SERVICE + 'getItems';
export const LOYALTY_CREATE_ITEM = apiServices.LOYALTY_MARKETPLACE_SERVICE + 'createItem';
export const LOYALTY_DELETE_ITEM = apiServices.LOYALTY_MARKETPLACE_SERVICE + 'deleteItem';
export const LOYALTY_UPDATE_ITEM_STATUS = apiServices.LOYALTY_MARKETPLACE_SERVICE + 'updateItemStatus';
export const LOYALTY_AVAILABILITY = apiServices.LOYALTY_MARKETPLACE_SERVICE + 'updateAvailability';
