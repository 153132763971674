import { useMemo } from 'react';
import { USER_STATUS, VERIFICATION_IDS } from 'constants/defines';
import { useApp } from 'utils/hooks/useApp';
import { useGeneric } from 'utils/hooks/useGeneric';

const getActiveTab = (pathname, url, tabs) => {
  const subpath = pathname.substr(url.length, pathname.length);
  const paths = subpath.split('/');
  if (!tabs) {
    return;
  }
  return tabs.find(tab => paths.indexOf(tab.path.slice(1)) !== -1);
};

const getUrlLastParam = (path, subPath, result) => {
  const res = getActiveTab(path, subPath);

  return res ? getUrlLastParam(path, subPath.concat(res), res) : result;
};

const useShouldVerifyDoc = () => {
  const { currentUser } = useApp();
  const { verificationConfigs } = useGeneric();

  return useMemo(
    () =>
      verificationConfigs?.some(
        ({ verificationType, isEnabled }) => verificationType === VERIFICATION_IDS.DOCUMENT && Number(isEnabled)
      ) &&
      currentUser?.status === String(USER_STATUS.PASSIVE) &&
      currentUser?.verifiedDoc === 'NO',
    [verificationConfigs, currentUser?.affiliateId]
  );
};

export { getActiveTab, getUrlLastParam, useShouldVerifyDoc };
